import React from 'react'
import { Link } from 'gatsby'
import { PageLayout, PageBreadcrumb } from '../../components'
import { t, l } from '../../lib/locale'

const ContentTestimonialsTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <div className="row">
        <div className="col-12">
          <h1>{t(page, 'title', lang)}</h1>
        </div>
      </div>
      <div className="row text-center mt-5">
        <div className="col-md-4 testimonials-col-item">
          <div className="testimonials-col-item-image mb-3">
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
          </div>
          <span>{l('block-testimonials-three-title', lang)}</span>
          <p>{l('block-testimonials-three-description', lang)}</p>
        </div>

        <div className="col-md-4 testimonials-col-item">
          <div className="testimonials-col-item-image mb-3">
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
          </div>
          <span>{l('block-testimonials-two-title', lang)}</span>
          <p>{l('block-testimonials-two-description', lang)}</p>
        </div>

        <div className="col-md-4 testimonials-col-item">
          <div className="testimonials-col-item-image mb-3">
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
            <i className="fas fa-lg fa-star testimonial"></i>
          </div>
          <span>{l('block-testimonials-one-title', lang)}</span>
          <p>{l('block-testimonials-one-description', lang)}</p>
        </div>
      </div>
    </PageLayout>
  )
}

export default ContentTestimonialsTemplate
